<template>
    <v-app-bar
            app
            color="#009A3F"
            dark
            clipped-left
            height='85px'
            class="v-menu-app"
    >
        <div class="d-flex align-center">

            <AppBarResponsive/>
            <v-img
                    alt="Vuetify Name"
                    class="shrink mt-1 hidden-sm-and-down"
                    contain
                    min-width="100"
                    src="../../assets/images/nav_logo.png"
                    width="150"
            />
        </div>

        <v-spacer></v-spacer>
        <div class="mr-4">
            <div class="subtitle-2 text-right">Bienvenido</div>
            <div class="subtitle-1 text-right">{{user.name}}</div>
        </div>
        <v-menu bottom offset-y transition="scale-transition" >
            <template v-slot:activator="{ on, attrs }">
                <v-avatar
                        v-bind="attrs"
                        v-on="on"
                        color="#D9D9D9"
                        size="44">
                    <span class="white--text overline"></span>
                </v-avatar>
            </template>

            <v-list-item-content style="background-color: #fff; margin-top: 5px;">
                <v-list-item>
                    <v-list-item-title ><v-btn v-on:click="logout()" style="background-color: #fff;">Cerrar sesión</v-btn></v-list-item-title>

                </v-list-item>
            </v-list-item-content>
        </v-menu>
    </v-app-bar>
</template>

<style lang="scss" scoped>
    /* .v-menu-responsive {
      display: none;
    } */
    @media (max-width: 768px) {
        .v-menu {
            display: none;
        }
        .v-menu-responsive {
            display: block;
        }
    }
</style>

<script>
    import AppBarResponsive from "@/components/layout/AppBarResponsive";

    export default {
        name: "AppBar",
        components: {
            AppBarResponsive
        },
        data: () => ({
            user: {}
            // isloggin:true
        }),
        mounted() {
            this.user = this.$store.getters.userSession;
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/login')
                    })
                    .catch(err => console.log(err));
            }
        },
    };
</script>
