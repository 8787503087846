<template>
    <v-navigation-drawer permanent clipped app class="v-navigation-drawer-fs">
        <sidebar-menu
                :menu="menuFiltered"
                :collapsed="collapsed"
                :theme="selectedTheme"
                :disableHover="false"
                :hideToggle="true"
                class="v-menu"
        />
    </v-navigation-drawer>
</template>

<script>
    export default {
        computed: {},
        name: "Sidebar",
        components: {},
        //realizar previa para cargar la nav deacuerdo a los roles.
        data() {
            return {
                menu: [
                    {
                        title: "Datos",
                        roles: ['admin'],
                        child: [
                            {
                                title: "Neumaticos",
                                // href: { path: "/tires" },
                                child: [
                                    {title: "Marca", href: {path: "/tires/brands"}},
                                    {title: "Diseño", href: {path: "/tires/designs"}},
                                    {title: "Medidas", href: {path: "/tires/measures"}},
                                    {
                                        title: "Partes del Neumático",
                                        href: {path: "/tires/parts"},
                                    },
                                    {
                                        title: "Fallas del Neumático",
                                        href: {path: "/tires/fails"},
                                    },
                                    {
                                        title: "Causal de retiro",
                                        href: {path: "/tires/causals"},
                                    },
                                    {
                                        title: "Tipo de Desgaste",
                                        href: {path: "/tires/wears"},
                                    },
                                    {
                                        title: "Nivel de Desgaste",
                                        href: {path: "/tires/wearlevels"},
                                    },
                                    {title: "Presión", href: {path: "/tires/pressure"}},
                                    {title: "Procedencia", href: {path: "/tires/origins"}},
                                    {title: "Diseño y Medidas", href: {path: "/tires/design-measurements"}},
                                ],
                            },
                            {
                                title: "Vehículos",
                                // href: { path: "/units" },
                                child: [
                                    {title: "Marcas", href: {path: "/units/brands"}},
                                    {title: "Modelos", href: {path: "/units/models"}},
                                    {
                                        title: "Tipo de unidad",
                                        href: {path: "/units/types"},
                                    },
                                    // {
                                    //   title: "Tipo de Desgaste",
                                    //   href: { path: "/units/typeraxis" },
                                    // },
                                    {
                                        title: "Tipo de unidad según eje",
                                        href: {path: "/units/wearlevels"},
                                    },
                                    {title: "Rutas", href: {path: "/units/routes"}},
                                    {title: "Combustible", href: {path: "/units/fuels"}},
                                ],
                            },


                            {
                                title: "Otros",
                                child: [
                                    {title: "Tipo de documento", href: {path: "/others/documents"}},
                                ]
                            },
                        ],
                    },
                    {
                        title: "Matenimiento",
                        roles: ['admin', 'customer', 'inspector'],
                        child: [
                            {
                                href: {path: "/drivers"},
                                title: "Choferes",
                            },
                            {
                                title: "Neumáticos",
                                href: {path: "/tires"},
                            },
                            {
                                href: {path: "/fleets"},
                                title: "Operación",
                            },
                            {
                                title: "Vehículos",
                                href: {path: "/units"},
                            },
                        ]
                    },
                    {
                        title: "Proceso",
                        roles: ['admin', 'customer', 'inspector'],
                        child: [
                            {
                                title: "Combustible",
                                href: {path: "/process/fuel"},
                            },
                            {
                                title: "Pesaje",
                                href: {path: "/process/pesaje"},
                            },
                            {
                                title: "Movimientos de Neumáticos",
                                href: {path: "/process/movements"},
                            },
                            {
                                title: "Control e inspección",
                                href: {path: "/process/inspections"},
                            },
                        ],
                    },
                    /*{
                        title: "Proceso",
                        roles: ['inspector'],
                        child: [
                            {
                                title: "Movimientos de Neumáticos",
                                href: {path: "/process/movements"},
                            },
                            {
                                title: "Control e inspección",
                                href: {path: "/process/inspections"},
                            },
                        ],
                    },*/
                    {
                        title: "Aplicacion",
                        roles: ['inspector'],
                        href: {path: "/inspector/mobile-app"},
                    },
                    {
                        title: "Reportes",
                        roles: ['admin', 'customer', 'inspector'],
                        child: [
                            {
                                title: "Inspecciones",
                                child: [
                                    {
                                        title: "Auditoría",
                                        href: {path: "/reports/inspections/audit"},
                                    },
                                    {
                                        title: "Proyecciones",
                                        href: {path: "/reports/projections"}
                                    },
                                    {
                                        title: "Desgastes Irregulares",
                                        href: {path: "/reports/irregular-wear"}
                                    }
                                ]
                            },
                            {
                                title: "Neumáticos",
                                child: [
                                    {
                                        title: "Recorrido de Neumáticos",
                                        href: {path: "/reports/tires/travel"}
                                    },
                                    {
                                        title: "Reporte de Finales",
                                        href: {path: "/reports/tires/finals"}
                                    },
                                    {
                                        title: "Reporte de Scrap",
                                        href: {path: "/reports/tires/scrap"}
                                    },
                                ]
                            },
                            {
                                title: 'Combustible',
                                child: [
                                    {
                                        title: 'Consumo por Unidad',
                                        href: {path: '/reports/fuel/vehicles'}
                                    },
                                    {
                                        title: 'Consumo por Neumático',
                                        href: {path: '/reports/fuel/tires'}
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        title: "Administración",
                        roles: ['admin'],
                        child: [
                            {
                                title: "Inspectores",
                                href: {path: "/administration/inspectors"},
                            },
                            {
                                title: "Clientes",
                                href: {path: "/administration/customers"},
                            },
                            {
                                title: "Usuario por Cliente",
                                href: {path: "/administration/customer/user"},
                            },
                            {
                                title: "Aplicación Móvil",
                                href: {path: "/administration/mobile-apps"},
                            },
                            {
                                title: "Administradores",
                                href: {path: "/administration/administrators"},
                            }
                        ]
                    },
                    // {
                    //   href: { path: "/window" },
                    //   title: "Ventana",
                    // },
                ],
                menuFiltered: [],
                collapsed: false,
                selectedTheme: "white-theme",
                isOnMobile: false,
            };
        },
        mounted() {
            const user = this.$store.getters.userSession;
            this.menu.forEach(item => {
                if (item.roles) {
                    let itemAdded = false;
                    item.roles.forEach(role => {
                        if (!itemAdded && user.roles.find(roleUser => roleUser.name === role)) {
                            this.menuFiltered.push(item);
                            itemAdded = true;
                        }
                    });
                } else {
                    this.menuFiltered.push(item);
                }
            });
        }
    };
</script>

<style type="text/css">
    .v-sidebar-menu .vsm--item a {
        height: 61px;
    }

    .v-sidebar-menu .vsm--item a:hover {
        text-decoration: none;
    }

    .v-sidebar-menu .vsm--item a span {
        padding-left: 30px;
        color: var(--unnamed-color-10131a);
        text-align: left;
        font: normal normal normal 16px/60px Roboto;
        letter-spacing: 0px;
        color: #10131a;
        opacity: 1;
    }

    .v-sidebar-menu.vsm_white-theme.vsm_expanded
    .vsm--item_open
    .vsm--link_level-1 {
        /*color: #ffffff !important;*/
        background-color: #ffffff !important;
    }

    .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
    .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
        -webkit-box-shadow: 3px 0px 0px 0px #4285f4 inset;
        box-shadow: 3px 0px 0px 0px #10131a inset !important;
    }

    .v-sidebar-menu.vsm_white-theme .vsm--dropdown .vsm--list {
        background-color: #ffffff !important;
    }

    .v-sidebar-menu .vsm--arrow:after {
        color: #000 !important;
    }

    .vsm--dropdown .vsm--list {
        padding-left: 18px !important;
    }
    .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1{
        background-color: #009A3F !important;
    }
    .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 span{
        color: white !important;
    }
    .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active{
        box-shadow: 3px 0px 0px 0px transparent inset !important;
    }
    .v-sidebar-menu.vsm_white-theme .vsm--link_level-2.vsm--link_active{
        background-color: #7DD462 !important;
    }
    .v-sidebar-menu.vsm_white-theme .vsm--link_level-3.vsm--link_active span{
        color: #0F582B !important;
        font-weight: bold !important;
    }
</style>
